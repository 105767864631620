import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Pasteis = () => (
  <>
    <SEO title="Pastéis" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Pastéis</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            00 - Coxinha c/ Catupiry{' '}
            <span style={{ whiteSpace: 'nowrap' }}>(1 unid.)</span>
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">6,50</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">01 - Carne</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">02 - Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">03 - Frango</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">04 - Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">05 - Lombo Canadense</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">06 - Calabresa</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">07 - Carne com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">08 - Carne com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">09 - Carne com Catupiry e Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">10 - Carne com Queijo e Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            11 - Carne com Catupiry, Ervilha, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">12 - Palmito com Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">13 - Palmito com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">14 - Palmito com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">15 - Palmito com Milho e Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">16 - Palmito com Milho e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            17 - Palmito com Milho, Ervilha, Catupiry e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">18 - Palmito com Carne</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">20 - Palmito com Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">21 - Queijo com Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">22 - Pizza</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">23 - Frango com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">24 - Frango com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">25 - Frango com Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">26 - Frango com Catupiry e Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            27 - Frango com Catupiry, Milho, Ervilha e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">28 - Frango com Palmito e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">29 - Calabresa com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">30 - Calabresa com Catupiry e Cebola</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            31 - Calabresa com Catupiry, Milho e Cebola
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            32 - Calabresa com Catupiry, Milho, Cebola, Bacon e Ervilha
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">33 - Calabresa com Frango e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">34 - Calabresa com Queijo e Cebola</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">35 - Calabresa com Catupiry e Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">36 - Lombo Canadense com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">37 - Lombo Canadense com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            38 - Lombo Canadense com Catupiry e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            39 - Lombo Canadense com Catupiry, Milho, Cebola e Ervilha
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">40 - Lombo Canadense com Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            41 - Lombo Canadense com Frango e Catupiry
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">42 - 4 Queijos</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">43 - 4 Queijos com Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">44 - 4 Queijos com Tomate</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">45 - 4 Queijos com Carne Seca</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">46 - Pizza com Carne</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">47 - Pizza com Frango e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">48 - Pizza com Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">49 - Pizza com Lombo Canadense</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">50 - Pizza com Calabresa</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">51 - Pizza com Carne Seca</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">52 - Carne Seca</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">53 - Carne Seca com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">54 - Carne Seca com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">55 - Carne Seca com Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">56 - Carne Seca com Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">57 - Milho com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">58 - Milho com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">78 - Tomate Seco</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">79 - Tomate Seco com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">80 - Tomate Seco com Queijo e Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            81 - Tomate Seco com Queijo, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">82 - Tomate Seco com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">83 - Tomate Seco com Catupiry e Milho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">84 - Tomate Seco com Frango</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">85 - Tomate Seco com Frango e Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            86 - Tomate Seco com Frango, Queijo e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            87 - Tomate Seco com Frango, Queijo, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">88 - Tomate Seco com Frango e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            89 - Tomate Seco com Frango Catupiry e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            90 - Tomate Seco com Frango Catupiry, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">91 - Tomate Seco com Palmito</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">92 - Tomate Seco com Palmito e Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            93 - Tomate Seco com Palmito, Queijo e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            94 - Tomate Seco com Palmito, Queijo, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            95 - Tomate Seco com Palmito e Catupiry
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            96 - Tomate Seco com Palmito, Catupiry e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            97 - Tomate Seco com Palmito, Catupiry, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">98 - Tomate Seco com Carne</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">99 - Tomate Seco com Carne e Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            100 - Tomate Seco com Carne, Queijo e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            101 - Tomate Seco com Carne, Queijo, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">102 - Tomate Seco com Carne e Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            103 -Tomate Seco com Carne, Catupiry e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            104 - Tomate Seco com Carne, Catupiry, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">105 -Tomate Seco com Lombo Canadense</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            106 -Tomate Seco com Lombo Canadense e Queijo
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            107 -Tomate Seco com Lombo Canadense, Queijo e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            109 -Tomate Seco com Lombo Canadense, Queijo, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            110 -Tomate Seco com Lombo Canadense e Catupiry
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            111 -Tomate Seco com Lombo Canadense, Catupiry e Milho
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            112 -Tomate Seco com Lombo Canadense, Milho e Bacon
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">113 -Tomate Seco com com 4 Queijos</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            114 -Tomate Seco com Catupiry e Azeitonas Pretas
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            115 - Queijo, Catupiry, Milho e Azeitonas Pretas
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            116 - Carne, Queijo, Catupiry e Azeitonas Pretas
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            118 - Ovo com Queijo e Azeitonas Pretas
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">119 - Ovo com Carne</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">120 - Ovo com Carne e Azeitonas Pretas</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">121 - Camarão</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">25,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">122 - Camarão com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">25,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">123 - Camarão com Mussarela</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">25,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">124 - Camarão com Cheddar</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">25,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">125 - Atum</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">126 - Atum com Mussarela</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">127 - Atum com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            128 - Hambúrguer com Queijo, Tomate e Catupiry
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            129 - Hambúrguer com Cheddar, Queijo e Tomate
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">130 - Hambúrguer com Queijo e Tomate</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Copinho de Vinagrete</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">2,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Maionese de Alho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">2,50</h4>
          </div>
        </div>
      </Item>

      <Slide left cascade duration={500}>
        <h1 className="menu-title">Pastéis Doces</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">60 - Chocolate</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            61 - Romeu e Julieta - Goiabada com Queijo
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">62 - Doce de Leite com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">63 - Chocolate Branco</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            68 - Banana com Canela, Açúcar e Chocolate
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            69 - Banana com Canela, Açúcar e Queijo
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">70 - Banana com Canela e Açúcar</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">17,00</h4>
          </div>
        </div>
      </Item>
    </Menu>
  </>
);

export default Pasteis;
